import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
// import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import CustomAxios from "../../lib/CustomAxios";

async function loginUser(credentials) {
  console.log("POSTING:", credentials);

  // const apiUrl = process.env.REACT_APP_API_URL ?? "";
  // console.log(["apiUrl", apiUrl]);

  const csrf = () =>
    CustomAxios.get("/sanctum/csrf-cookie").catch((error) => {
      console.log("ERROR", error);
    });

  console.log(":: csrf", csrf);

  const login = async () => {
    await csrf();

    CustomAxios.post("login", credentials)
      .then((response) => {
        console.log("_RESPONSE: ", response);
        if (response.status === 200) {
          // console.log("THEN", response.json());
          return response.json();
        }
        response.headers.forEach(function (val, key) {
          console.log(key + " -> " + val);
        });
      })
      .catch((error) => {
        console.log("ERROR", error);
        // handle error
      });
  };

  const result = await login();
  return result;
}

export default function HorizontalLoginForm({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [remember, setRemember] = useState();

  const handleSubmit = async (e) => {
    console.log("handleSubmit");
    e.preventDefault();
    const token = await loginUser({
      email: username,
      password,
      remember: remember === "on",
    });
    console.log("TOKEN: ", token);
    setToken(token);
  };

  return (
    <>
      <Form className="d-flex" onSubmit={handleSubmit}>
        <Form.Control
          type="text"
          placeholder="Username"
          className="me-2"
          aria-label="Username"
          htmlSize="10"
          id="email"
          onChange={(e) => setUserName(e.target.value)}
        />
        <Form.Control
          type="password"
          placeholder="Password"
          className="me-2"
          aria-label="Password"
          htmlSize="10"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            inline
            type="switch"
            label="Remember me"
            id="remember"
            onChange={(e) => setRemember(e.target.value)}
          />
        </Form.Group>
        <Button type="submit" variant="outline-success">
          Login
        </Button>
      </Form>
    </>
  );
}

HorizontalLoginForm.propTypes = {
  setToken: PropTypes.func.isRequired,
};
