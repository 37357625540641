import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from './App';
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import Home from "./routes/home";

import ErrorPage from "./error-page";
import UserLogin from "./routes/user/login";
import Dashboard from "./routes/dashboard";
import Logout from "./routes/user/Logout";
import SignUp from "./routes/user/SignUp";
import HtmlContent from "./components/content/Html";

import AccountSettings from "./components/user/AccountSettings";
import Preferences from "./components/user/Preferences";
import Billing from "./components/user/Billing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/html/*",
        element: <HtmlContent />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "/user/account-settings",
        element: <AccountSettings />,
      },
      {
        path: "/user/preferences",
        element: <Preferences />,
      },
      {
        path: "/user/Billing",
        element: <Billing />,
      },
    ],
  },
  {
    path: "/login",
    element: <UserLogin />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
