import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import CustomAxios from "../../lib/CustomAxios";

async function registerUser(userData) {
  console.log("Posting: ", userData);

//   const apiUrl = process.env.REACT_APP_API_URL ?? "";
//   console.log("apiUrl: ", apiUrl);

  const csrf = () => CustomAxios.get("sanctum/csrf-cookie").catch((error) => {
    console.log("ERROR", error);
  });

  console.log(':: csrf', csrf);

  const register = async () => {
    await csrf();
    CustomAxios
      .post("register", userData)
      .then((response) => {
        console.log("Post.Response", response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
  console.log('REGISTER:  ', await register);

  const result = await register();
  return {
    result,
  }
}

export default function SignUp() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setPasswordConfirmation] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await registerUser({
      name,
      email,
      password,
      password_confirmation,
    });
    console.log("Result: ", result);
  };

  return (
    <>
      Sign Up here
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Your Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Name"
            className="me-2"
            aria-label="Name"
            //   htmlSize="40"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Text>Enter your real name</Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Username"
            // className="me-2"
            aria-label="Username"
            //   htmlSize="40"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text>Your email will be your username</Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            type="password"
            placeholder="Password"
            //   className="me-2"
            aria-label="Password"
            //   htmlSize="40"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Text>Enter your password</Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            type="password"
            placeholder="Password confirmation"
            //   className="me-2"
            aria-label="Password confirmation"
            //   htmlSize="40"
            id="password_confirmation"
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <Form.Text>Enter your password again, to confirm</Form.Text>
        </Form.Group>
        <br />
        <Button type="submit" variant="outline-success">
          Sign Up
        </Button>
        <Button variant="link">Forgot Password?</Button>
        <Button variant="link">Back to Login</Button>
      </Form>
    </>
  );
}
