import { useMatches } from "react-router-dom";

export default function Html() {
    const matches = useMatches();

    console.log('> html > ', matches);

    return (
        <>
        HTML
        </>
    );
}
