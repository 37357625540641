import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../logo.svg";
import ApiStatus from "../ApiStatus"; // Replace with the correct path to the ApiStatus component file
import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";

import UserAccountBar from "../components/user/UserAccountBar";
import useToken from "../useToken";

export default function Root() {
  const { token, setToken } = useToken();

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo} className="App-logo" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link href="#home">Home</Nav.Link> */}
              {/* <Nav.Link href="#link">Link</Nav.Link> */}
              <Nav.Link href="/html/about">About Us</Nav.Link>
              <Nav.Link href="/html/privacy-policy">Privacy Policy</Nav.Link>
              <Nav.Link href="/html/terms-of-service">
                Terms of Service
              </Nav.Link>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown> */}
              <Navbar.Text>
                <ApiStatus />
              </Navbar.Text>
              <UserAccountBar setToken={setToken} token={token} />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div id="detail">
        {token !== undefined ? <div>TOKEN</div> : <div>NO TOKEN</div>}

        <Outlet />
      </div>
    </>
  );
}
