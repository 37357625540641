import Axios from "axios";

Axios.defaults.withCredentials = true;
const CustomAxios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});


export default CustomAxios