import React, { useEffect } from "react";
import useToken from "../../useToken";

export default function Logout() {
  const { setToken } = useToken();

  useEffect(() => {
    console.log("Need to logout now");
    setToken({});
  });

  return <>Logout</>;
}
