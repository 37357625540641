import React, { useState, useEffect } from "react";

import CustomAxios from "./lib/CustomAxios";

const ApiStatus = () => {
  const [apiStatus, setApiStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const apiUrl = process.env.REACT_APP_API_URL ?? "";
        // console.log(["apiUrl", apiUrl]);
        // console.log(["process.env", process.env]);


        CustomAxios.get("/sanctum/csrf-cookie")
        .then(async(response) => {
          const statusResponse = CustomAxios.get("api/status"); // Replace with your API endpoint
          const jsonResponse = await statusResponse;
          console.log('jsonResponse: ', jsonResponse.data);
          if (jsonResponse.data.redis === 'ok') {
            setApiStatus("OK");
          } else {
            setApiStatus("ERROR");
          }

        })
        .catch((error) => {
          console.log('Axios-Error: ', error);
        });

      } catch (error) {
        setApiStatus("ERROR");
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      {apiStatus === "OK" ? (
        <div
          style={{ backgroundColor: "green", color: "white", padding: "4px" }}
        >
          API OK
        </div>
      ) : (
        <div style={{ backgroundColor: "red", color: "white", padding: "4px" }}>
          ERROR
        </div>
      )}
    </div>
  );
};

export default ApiStatus;
