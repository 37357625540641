import PropTypes from "prop-types";

import HorizontalLoginForm from "./HorizontalLoginForm";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function UserAccountBar({ token, setToken }) {
  if (!token) {
    return (
      <>
        <Nav.Link href="/signup">Sign Up</Nav.Link>
        <HorizontalLoginForm setToken={setToken} />
      </>
    );
  }

  console.log("::UserAccountBar: ", token);
  return (
    <>
      <NavDropdown title="Profile" id="basic-nav-dropdown">
        <NavDropdown.Item href="/user/account-settings">Account Settings</NavDropdown.Item>
        <NavDropdown.Item href="/user/preferences">Preferences</NavDropdown.Item>
        <NavDropdown.Item href="/user/billing">Billing</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
      </NavDropdown>
    </>
  );
}

UserAccountBar.propTypes = {
  setToken: PropTypes.func.isRequired,
  // token: PropTypes.func.isRequired,
};
